@use '../../assets/stylesheets/abstracts/' as *;

.support {
  max-width: 120rem;
  margin-inline: auto;
}

.wrapper {
  padding: 3rem;
}

.heading {
  @include font(3.5rem, 400);
  line-height: 1.5;
}

.para {
  @include font(1.6rem, 400);
  line-height: 1.5;
  margin-top: 2rem;
}
