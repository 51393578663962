@use '../../assets/stylesheets/abstracts/' as *;

html {
  @include lg-max {
    font-size: 9px;
  }

  @include sm-max {
    font-size: 8px;
  }
}

.auth-ui {
  position: relative;
  min-height: 100vh;
  background-color: #f8dd30;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .container {
    position: relative;
    width: 800px;
    height: 500px;
    background: #fff;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .user {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;

    .imgBx {
      position: relative;
      width: 50%;
      height: 100%;
      background: #ff0;
      transition: 0.5s;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .formBx {
      position: relative;
      width: 50%;
      height: 100%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;
      transition: 0.5s;

      form h2 {
        font-size: 1.8rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
        color: #555;
      }

      form input {
        position: relative;
        width: 100%;
        padding: 10px;
        background: #f5f5f5;
        color: #333;
        border: none;
        outline: none;
        box-shadow: none;
        margin: 8px 0;
        font-size: 1.4rem;
        letter-spacing: 1px;
        font-weight: 300;
      }

      form input[type='submit'] {
        max-width: 100px;
        background: #677eff;
        color: #fff;
        cursor: pointer;
        font-size: 1.4rem;
        font-weight: 500;
        letter-spacing: 1px;
        transition: 0.5s;
      }

      form .signup {
        position: relative;
        margin-top: 20px;
        font-size: 1.2rem;
        letter-spacing: 1px;
        color: #555;
        text-transform: uppercase;
        font-weight: 300;
      }

      form .signup a {
        font-weight: 600;
        text-decoration: none;
        color: #677eff;
      }
    }
  }

  .signupBx {
    pointer-events: none;
  }

  .container.active .signupBx {
    pointer-events: initial;
  }

  .signupBx .formBx {
    left: 100%;
  }

  .container.active .signupBx .formBx {
    left: 0;
  }

  .signupBx .imgBx {
    left: -100%;
  }

  .container.active .signupBx .imgBx {
    left: 0%;
  }

  .signinBx .formBx {
    left: 0%;
  }

  .container.active .signinBx .formBx {
    left: 100%;
  }

  .signinBx .imgBx {
    left: 0%;
  }

  .container.active .signinBx .imgBx {
    left: -100%;
  }
}

@media (max-width: 991px) {
  .auth-ui .container {
    max-width: 400px;
  }

  .auth-ui .container .imgBx {
    display: none;
  }

  .auth-ui .container .user .formBx {
    width: 100%;
    padding: 2rem;
  }
}
