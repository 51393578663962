@use '../../assets/stylesheets/abstracts/' as *;

.contact {
  max-width: 120rem;
  margin-inline: auto;
}

.wrapper {
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 3rem;
  max-width: 100%;

  &>div {
    max-width: 100%;
  }
}

.logo {
  padding-block: 3rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.name {
  @include center-verticel;
  gap: 1rem;
}

.item {
  @include center-verticel;
  @include font(1.6rem, 400);
  gap: 1rem;
}

.heading {
  @include font(2.2rem, 800);
  text-transform: uppercase;
  margin-block: 3rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

input {
  @include font(1.6rem, 400);
  padding: 1rem;
  border: 1px solid $light-white;
  border-radius: 5px;
}

.textarea {
  resize: none;
  background: none;
  max-width: 100%;
  width: 100%;
  border: 1px solid $light-white;
  border-radius: 5px;
  padding: 1rem;
  box-sizing: border-box;
}

.map {
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 400px;
}
