@use '../../assets/stylesheets/abstracts/' as *;

.overlay {
  position: fixed;
  inset: 0;
  z-index: -1;
}

.side {
  @include slow;
  max-width: 45rem;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  height: 100dvh;
  background-color: $white;
  padding: 1rem 2rem;
  transform-origin: 100% 0;
  transform: perspective(600px) rotateY(-90deg);
  z-index: 3;
}

.wrapper {
  @include font(1.8rem, 400);
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  // padding-bottom: 57px;
}

.top {
  @include between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid $light-white;
}

.topLeft {
  display: flex;
  flex-direction: column;
  line-height: 1.5;

  span:last-child {
    font-size: 1.4rem;
    color: $text-grey;
  }
}

.topRight {
  @include centerElement;
  @include slow;
  padding: 5px;
  max-width: 3rem;
  max-height: 3rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid $gray-dark;
  cursor: pointer;

  &:hover {
    color: $primary-color;
    border-color: $primary-color;
  }
}

.bottom {
  flex: 1;
  overflow-y: auto;
}

.item {
  display: flex;
  flex: 1;
  gap: 1rem;
  padding-block: 2rem;
  border-bottom: 1px solid $light-white;
}

.imageWrap {
  width: 10rem;
  height: 7rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info {
  flex: 1;
}

.name {
  @include slow;
  @include font(1.6rem, 800);
  @include limitLine(2);

  &:hover {
    color: $primary-color;
  }
}

.infoBottom {
  @include between;
  margin-top: 1rem;
}

.quantity {
  font-size: 1.4rem;
  color: $text-grey;
  line-height: 1.5;
}

.cost {
  text-align: right;
  padding-bottom: 8px;
  margin-bottom: 5px;
}

.delete {
  @include font(1.4rem, 400);
  padding-top: 5px;
  color: $primary-color;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.payTop {
  @include between;
  align-items: center;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $light-white;
}

.money,
.moneyText {
  font-weight: 800;
}

.overlay.active {
  background-color: #00000057;
  z-index: 2;
}

.side.active {
  transform: perspective(600px) rotateY(0deg);
}
