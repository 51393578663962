@use '../assets/stylesheets/abstracts/' as *;

.step {
  @include font(1.6rem, 400);
  line-height: 1.5;
  margin-bottom: 2rem;
}

.heading {
  @include font(2.5rem 400);
  margin-bottom: 2rem;
}

.heading {
  @include font(2.5rem, 400);
  line-height: 1.5;
  margin-bottom: 3rem;
}
