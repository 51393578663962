$primary-color: #f03248;
$white: #fff;
$light-white: #ccc;
$red: #dc3545;
$gray: #6c757d;
$gray-dark: #343a40;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$text-grey: #979c9e;
