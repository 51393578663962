@use '../../assets/stylesheets/abstracts/' as *;

.basic-info {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 100%;
  width: 100%;
  background-color: $white;
  padding: 2rem;
  flex-direction: row;

  @include md-max {
    flex-direction: column;
    gap: 5rem;
    align-items: center;
  }
}

.product-images {
  flex: 1;
  width: 100%;

  @include md-min {
    width: 35%;
  }
}

.product-info {
  @include font(1.6rem);
  width: 100%;
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 2rem;
}

// swiper style
.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  // height: 500px !important;

}

.mySwiper {
  .swiper-slide {
    @include slow();
    border: 1px solid $light-white;
    box-sizing: border-box;
    border-radius: 8px;
    aspect-ratio: 1 / 1;
    padding: 10px;
    background-color: #fff;
    cursor: grab;
    position: relative;

    &:hover {
      border-color: $primary-color;
    }
  }

  .swiper-slide-thumb-active {
    border-color: $primary-color;
  }
}

.favorite {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 2.2rem;
  color: $primary-color;
  cursor: pointer;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
  border: 1px solid $light-white;
}

.mySwiper {
  box-sizing: border-box;
  height: auto;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-image {
  object-fit: cover;
}

.swiper-wrapper {
  z-index: -1;
}
